import { default as _91_46_46_46slug_93XYH4S3Bwy9Meta } from "/var/www/zuerioberland.first-media.ch/pages/[...slug].vue?macro=true";
import { default as AppM9vO6Hmw8kMeta } from "/var/www/zuerioberland.first-media.ch/pages/App.vue?macro=true";
import { default as _91_46_46_46slug_93Yzm6oQRBDEMeta } from "/var/www/zuerioberland.first-media.ch/pages/business/[id]/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93dIvvCiMhCgMeta } from "/var/www/zuerioberland.first-media.ch/pages/businesses/[...slug].vue?macro=true";
import { default as _91id_936TqWttvTlgMeta } from "/var/www/zuerioberland.first-media.ch/pages/certification/[id].vue?macro=true";
import { default as _91_46_46_46slug_93L2HzwwWQjdMeta } from "/var/www/zuerioberland.first-media.ch/pages/certification/check/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93jLxskgIKxbMeta } from "/var/www/zuerioberland.first-media.ch/pages/certification/create/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93miFvWaIzcEMeta } from "/var/www/zuerioberland.first-media.ch/pages/certifications/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_932NngiXUF0aMeta } from "/var/www/zuerioberland.first-media.ch/pages/configuration/action-message/[...slug].vue?macro=true";
import { default as indexEtO4PnJVjLMeta } from "/var/www/zuerioberland.first-media.ch/pages/configuration/action-messages/index.vue?macro=true";
import { default as index89FgNmQFh6Meta } from "/var/www/zuerioberland.first-media.ch/pages/configuration/categories/index.vue?macro=true";
import { default as _91_46_46_46slug_93OPGO9feIqvMeta } from "/var/www/zuerioberland.first-media.ch/pages/configuration/category/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93pYYeyVtr0mMeta } from "/var/www/zuerioberland.first-media.ch/pages/configuration/help-text/[...slug].vue?macro=true";
import { default as indexGlJqTBWcBhMeta } from "/var/www/zuerioberland.first-media.ch/pages/configuration/help-texts/index.vue?macro=true";
import { default as indexB0JEQoSKBvMeta } from "/var/www/zuerioberland.first-media.ch/pages/configuration/import/index.vue?macro=true";
import { default as _91_46_46_46slug_933WQTVRAqDAMeta } from "/var/www/zuerioberland.first-media.ch/pages/configuration/marketing-question/[...slug].vue?macro=true";
import { default as index7Ap7wCXmqSMeta } from "/var/www/zuerioberland.first-media.ch/pages/configuration/marketing-questions/index.vue?macro=true";
import { default as indexKiImIhI08vMeta } from "/var/www/zuerioberland.first-media.ch/pages/configuration/templates/index.vue?macro=true";
import { default as Confirm_45EmailfKLKVlUGdeMeta } from "/var/www/zuerioberland.first-media.ch/pages/Confirm-Email.vue?macro=true";
import { default as indexl7RQhxUtGeMeta } from "/var/www/zuerioberland.first-media.ch/pages/export/index.vue?macro=true";
import { default as index3yJRynfQ7XMeta } from "/var/www/zuerioberland.first-media.ch/pages/help/index.vue?macro=true";
import { default as Imprint4b3v4rSq9aMeta } from "/var/www/zuerioberland.first-media.ch/pages/Imprint.vue?macro=true";
import { default as index9BGdKrQQxLMeta } from "/var/www/zuerioberland.first-media.ch/pages/index.vue?macro=true";
import { default as LoginUxbcxCY151Meta } from "/var/www/zuerioberland.first-media.ch/pages/Login.vue?macro=true";
import { default as MaintenanceySWWIRAAfGMeta } from "/var/www/zuerioberland.first-media.ch/pages/Maintenance.vue?macro=true";
import { default as _91_46_46_46slug_93BbqIXSviHmMeta } from "/var/www/zuerioberland.first-media.ch/pages/organization/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93XiY4pO8QLZMeta } from "/var/www/zuerioberland.first-media.ch/pages/organizations/[...slug].vue?macro=true";
import { default as Privacymkwg1fctEkMeta } from "/var/www/zuerioberland.first-media.ch/pages/Privacy.vue?macro=true";
import { default as _91_46_46_46slug_93np14NE67gkMeta } from "/var/www/zuerioberland.first-media.ch/pages/product/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93iVyJrkLZKnMeta } from "/var/www/zuerioberland.first-media.ch/pages/products/[...slug].vue?macro=true";
import { default as Reset_45PasswordjPJ0E4rOfpMeta } from "/var/www/zuerioberland.first-media.ch/pages/Reset-Password.vue?macro=true";
import { default as TermsoT03YknzWuMeta } from "/var/www/zuerioberland.first-media.ch/pages/Terms.vue?macro=true";
import { default as _91_46_46_46slug_93o9zWl9ClKYMeta } from "/var/www/zuerioberland.first-media.ch/pages/user/[id]/[...slug].vue?macro=true";
import { default as Delete2iPtQxhUWRMeta } from "/var/www/zuerioberland.first-media.ch/pages/user/[id]/Delete.vue?macro=true";
import { default as AddFGcIKLitxHMeta } from "/var/www/zuerioberland.first-media.ch/pages/user/Add.vue?macro=true";
import { default as indexKEwe10ksGsMeta } from "/var/www/zuerioberland.first-media.ch/pages/users/index.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/var/www/zuerioberland.first-media.ch/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "App",
    path: "/App",
    component: () => import("/var/www/zuerioberland.first-media.ch/pages/App.vue").then(m => m.default || m)
  },
  {
    name: "business-id-slug",
    path: "/business/:id()/:slug(.*)*",
    component: () => import("/var/www/zuerioberland.first-media.ch/pages/business/[id]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "businesses-slug",
    path: "/businesses/:slug(.*)*",
    component: () => import("/var/www/zuerioberland.first-media.ch/pages/businesses/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "certification-id",
    path: "/certification/:id()",
    component: () => import("/var/www/zuerioberland.first-media.ch/pages/certification/[id].vue").then(m => m.default || m)
  },
  {
    name: "certification-check-slug",
    path: "/certification/check/:slug(.*)*",
    meta: _91_46_46_46slug_93L2HzwwWQjdMeta || {},
    component: () => import("/var/www/zuerioberland.first-media.ch/pages/certification/check/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "certification-create-slug",
    path: "/certification/create/:slug(.*)*",
    meta: _91_46_46_46slug_93jLxskgIKxbMeta || {},
    component: () => import("/var/www/zuerioberland.first-media.ch/pages/certification/create/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "certifications-slug",
    path: "/certifications/:slug(.*)*",
    component: () => import("/var/www/zuerioberland.first-media.ch/pages/certifications/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "configuration-action-message-slug",
    path: "/configuration/action-message/:slug(.*)*",
    component: () => import("/var/www/zuerioberland.first-media.ch/pages/configuration/action-message/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "configuration-action-messages",
    path: "/configuration/action-messages",
    component: () => import("/var/www/zuerioberland.first-media.ch/pages/configuration/action-messages/index.vue").then(m => m.default || m)
  },
  {
    name: "configuration-categories",
    path: "/configuration/categories",
    component: () => import("/var/www/zuerioberland.first-media.ch/pages/configuration/categories/index.vue").then(m => m.default || m)
  },
  {
    name: "configuration-category-slug",
    path: "/configuration/category/:slug(.*)*",
    component: () => import("/var/www/zuerioberland.first-media.ch/pages/configuration/category/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "configuration-help-text-slug",
    path: "/configuration/help-text/:slug(.*)*",
    component: () => import("/var/www/zuerioberland.first-media.ch/pages/configuration/help-text/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "configuration-help-texts",
    path: "/configuration/help-texts",
    component: () => import("/var/www/zuerioberland.first-media.ch/pages/configuration/help-texts/index.vue").then(m => m.default || m)
  },
  {
    name: "configuration-import",
    path: "/configuration/import",
    component: () => import("/var/www/zuerioberland.first-media.ch/pages/configuration/import/index.vue").then(m => m.default || m)
  },
  {
    name: "configuration-marketing-question-slug",
    path: "/configuration/marketing-question/:slug(.*)*",
    component: () => import("/var/www/zuerioberland.first-media.ch/pages/configuration/marketing-question/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "configuration-marketing-questions",
    path: "/configuration/marketing-questions",
    component: () => import("/var/www/zuerioberland.first-media.ch/pages/configuration/marketing-questions/index.vue").then(m => m.default || m)
  },
  {
    name: "configuration-templates",
    path: "/configuration/templates",
    component: () => import("/var/www/zuerioberland.first-media.ch/pages/configuration/templates/index.vue").then(m => m.default || m)
  },
  {
    name: "Confirm-Email",
    path: "/Confirm-Email",
    component: () => import("/var/www/zuerioberland.first-media.ch/pages/Confirm-Email.vue").then(m => m.default || m)
  },
  {
    name: "export",
    path: "/export",
    component: () => import("/var/www/zuerioberland.first-media.ch/pages/export/index.vue").then(m => m.default || m)
  },
  {
    name: "help",
    path: "/help",
    component: () => import("/var/www/zuerioberland.first-media.ch/pages/help/index.vue").then(m => m.default || m)
  },
  {
    name: "Imprint",
    path: "/Imprint",
    meta: Imprint4b3v4rSq9aMeta || {},
    component: () => import("/var/www/zuerioberland.first-media.ch/pages/Imprint.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: index9BGdKrQQxLMeta || {},
    component: () => import("/var/www/zuerioberland.first-media.ch/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "Login",
    path: "/Login",
    meta: LoginUxbcxCY151Meta || {},
    component: () => import("/var/www/zuerioberland.first-media.ch/pages/Login.vue").then(m => m.default || m)
  },
  {
    name: "Maintenance",
    path: "/Maintenance",
    meta: MaintenanceySWWIRAAfGMeta || {},
    component: () => import("/var/www/zuerioberland.first-media.ch/pages/Maintenance.vue").then(m => m.default || m)
  },
  {
    name: "organization-slug",
    path: "/organization/:slug(.*)*",
    component: () => import("/var/www/zuerioberland.first-media.ch/pages/organization/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "organizations-slug",
    path: "/organizations/:slug(.*)*",
    component: () => import("/var/www/zuerioberland.first-media.ch/pages/organizations/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "Privacy",
    path: "/Privacy",
    meta: Privacymkwg1fctEkMeta || {},
    component: () => import("/var/www/zuerioberland.first-media.ch/pages/Privacy.vue").then(m => m.default || m)
  },
  {
    name: "product-slug",
    path: "/product/:slug(.*)*",
    component: () => import("/var/www/zuerioberland.first-media.ch/pages/product/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "products-slug",
    path: "/products/:slug(.*)*",
    component: () => import("/var/www/zuerioberland.first-media.ch/pages/products/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "Reset-Password",
    path: "/Reset-Password",
    component: () => import("/var/www/zuerioberland.first-media.ch/pages/Reset-Password.vue").then(m => m.default || m)
  },
  {
    name: "Terms",
    path: "/Terms",
    meta: TermsoT03YknzWuMeta || {},
    component: () => import("/var/www/zuerioberland.first-media.ch/pages/Terms.vue").then(m => m.default || m)
  },
  {
    name: "user-id-slug",
    path: "/user/:id()/:slug(.*)*",
    component: () => import("/var/www/zuerioberland.first-media.ch/pages/user/[id]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "user-id-Delete",
    path: "/user/:id()/Delete",
    component: () => import("/var/www/zuerioberland.first-media.ch/pages/user/[id]/Delete.vue").then(m => m.default || m)
  },
  {
    name: "user-Add",
    path: "/user/Add",
    component: () => import("/var/www/zuerioberland.first-media.ch/pages/user/Add.vue").then(m => m.default || m)
  },
  {
    name: "users",
    path: "/users",
    component: () => import("/var/www/zuerioberland.first-media.ch/pages/users/index.vue").then(m => m.default || m)
  }
]